import React from 'react';
import Layout from '../components/layout';
import Article from '../components/article';
import ArticleBlock from '../components/articleBlock';
import LoadArticlesButton from '../components/LoadArticlesButton';
import { graphql } from 'gatsby';
import unique from 'uniqid';
import get from 'lodash/get';
import PropTypes from 'prop-types';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: 1,
      index: 0,
      moreVisible: true,
    };
  }

  componentDidMount() {
    this.context.mixpanel.track('Home.Load');
  }

  loadArticles = () => {};

  handleClick = (event) => {
    let nextItems = this.state.items + 1;
    this.setState({ items: nextItems });
  };

  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    const Articles = posts
      .filter((post) => {
        // this is just a placeholder that always returns true
        // if you wanted to filter the list of articles this is where you would do it
        if (post.node.frontmatter.draft) {
          return false;
        }
        return true;
      })
      .map((post) => {
        return (
          <Article
            key={unique()}
            title={post.node.frontmatter.title}
            summary={post.node.frontmatter.summary}
            url={post.node.frontmatter.url}
            date={post.node.frontmatter.date}
          />
        );
      });

    let articleBlocks = [];
    let articleList = [];

    // make blocks of articles, 5 articles each blcok
    Articles.forEach((article) => {
      articleList.push(article);
      // we want 5 articles per block
      if (articleList.length === 5) {
        // so that the links are in order of the markdown files: 1.md, 2.md etc.
        //articleList.reverse();
        articleBlocks.push(
          <ArticleBlock date={article.props.date} key={unique()}>
            {Array.from(articleList)}
          </ArticleBlock>,
        );
        articleList.length = 0;
      }
    });

    let items = this.state.index + this.state.items;
    let pagedListOfArticles = articleBlocks.slice(this.state.index, items);

    return (
      <Layout>
        {pagedListOfArticles}
        {this.state.moreVisible && (
          <LoadArticlesButton clickHandler={this.handleClick}>More</LoadArticlesButton>
        )}
      </Layout>
    );
  }
}

IndexPage.contextTypes = {
  // mixpanel must be declared on contextTypes
  mixpanel: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            summary
            url
            draft
          }
        }
      }
    }
  }
`;
