import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Article = (props) => (
  <a href={props.url} className="c-article">
    <article>
      <h2>{props.title}</h2>
      <p>{props.summary}</p>
      <small>{props.date}</small>
    </article>
  </a>
);

Article.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
};

Article.defaultProps = {
  summary: '',
};

export default Article;
